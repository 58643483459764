<script>
import { MoreHorizontalIcon } from "@zhuowenli/vue-feather-icons";
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import SubscriptionServices from "../../services/SubscriptionServices";
import appConfig from "../../../app.config";
import store from "../../state/store";

export default {
  page: {
    title: "Liste des souscriptions",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Liste des souscriptions",
      items: [
        {
          text: "Souscriptions",
          href: "/",
        },
        {
          text: "Liste des souscriptions",
          active: true,
        },
      ],
      projectListWidgets2: [
        {
          id: 1,
          label: "Multipurpose landing template",
          status: "Inprogess",
          statusClass: "warning",
          deadline: "18 Sep, 2021",
          subItem: [
            {
              id: 1,
              imgNumber: "D",
              bgColor: "danger",
            },
            {
              id: 2,
              imgTeam: require("@/assets/images/users/avatar-5.jpg"),
            },
            {
              id: 3,
              imgTeam: require("@/assets/images/users/avatar-6.jpg"),
            },
            {
              id: 4,
              imgNumber: "+",
            },
          ],
          progressBar: "50%",
        },
        {
          id: 2,
          label: "Dashboard UI",
          status: "Completed",
          statusClass: "success",
          deadline: "10 Jun, 2021",
          subItem: [
            {
              id: 1,
              imgTeam: require("@/assets/images/users/avatar-7.jpg"),
            },
            {
              id: 2,
              imgTeam: require("@/assets/images/users/avatar-8.jpg"),
            },
            {
              id: 3,
              imgNumber: "+",
            },
          ],
          progressBar: "95%",
        },
        {
          id: 3,
          label: "Vector Images",
          status: "Inprogess",
          statusClass: "warning",
          deadline: "08 Apr, 2021",
          subItem: [
            {
              id: 1,
              imgNumber: "C",
              bgColor: "info",
            },
            {
              id: 2,
              imgNumber: "+",
            },
          ],
          progressBar: "41%",
        },
        {
          id: 4,
          label: "Authentication",
          status: "Inprogess",
          statusClass: "warning",
          deadline: "22 Nov, 2021",
          subItem: [
            {
              id: 1,
              imgTeam: require("@/assets/images/users/avatar-8.jpg"),
            },
            {
              id: 2,
              imgNumber: "+",
            },
          ],
          progressBar: "35%",
        },
      ],
      subscriptions: [],
    };
  },
  components: {
    Layout,
    PageHeader,
    // Multiselect,
    MoreHorizontalIcon,
  },
  async beforeRouteEnter() {
    store.dispatch("setGlobalLoading", true);
    const subscriptionsData = [];
    await SubscriptionServices.getAll()
      .then((data) => {
        const monthNames = [
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Aout",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        data.subscriptions.forEach((row) => {
          var dd = new Date(row.created_at);
          row.publishedDate =
            dd.getDate() +
            " " +
            monthNames[dd.getMonth()] +
            ", " +
            dd.getFullYear();
          var hours = dd.getHours();
          var minutes = dd.getMinutes();
          var ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          var strTime = hours + ":" + minutes + " " + ampm;
          row.publishedtime = strTime;
          // row.image_src =
          //   "https://api-node.themesbrand.website/images/products/" + row.image;
          row.image_src = row.image;
          // row.image_src = `@/assets/images/products/img-8.png`;
          subscriptionsData.push(row);
        });
      })
      .catch((er) => {
        console.log(er);
      })
      .finally(() => {
        store.dispatch("setGlobalLoading", false);
      });

    localStorage.setItem(
      "@LIST_SUBSCRIPTIONS",
      JSON.stringify(subscriptionsData)
    );
  },
  beforeMount() {
    this.subscriptions = JSON.parse(
      localStorage.getItem("@LIST_SUBSCRIPTIONS") || "[]"
    );
  },
  methods: {
    async getSubscriptions() {
      store.dispatch("setGlobalLoading", true);
      const subscriptionsData = [];
      await SubscriptionServices.getAll()
        .then((data) => {
          const monthNames = [
            "Jan",
            "Fev",
            "Mar",
            "Avr",
            "Mai",
            "Juin",
            "Juil",
            "Aout",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ];
          data.subscriptions.forEach((row) => {
            var dd = new Date(row.created_at);
            row.publishedDate =
              dd.getDate() +
              " " +
              monthNames[dd.getMonth()] +
              ", " +
              dd.getFullYear();
            var hours = dd.getHours();
            var minutes = dd.getMinutes();
            var ampm = hours >= 12 ? "PM" : "AM";
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            hours = hours < 10 ? "0" + hours : hours;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            var strTime = hours + ":" + minutes + " " + ampm;
            row.publishedtime = strTime;
            // row.image_src =
            //   "https://api-node.themesbrand.website/images/products/" + row.image;
            row.image_src = row.image;
            // row.image_src = `@/assets/images/products/img-8.png`;
            subscriptionsData.push(row);
          });
        })
        .catch((er) => {
          console.log(er);
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });

      this.subscriptions = subscriptionsData;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row g-4 mb-3">
      <div class="col-sm-auto">
        <!-- <div>
          <router-link to="/apps/projects-create" class="btn btn-success"
            ><i class="ri-add-line align-bottom me-1"></i> Add New</router-link
          >
        </div> -->
      </div>
      <div class="col-sm">
        <div class="d-flex justify-content-sm-end gap-2">
          <div class="search-box ms-2">
            <input
              type="text"
              class="form-control"
              placeholder="Recherche..."
            />
            <i class="ri-search-line search-icon"></i>
          </div>

          <!-- <Multiselect
            class="multiselect form-control w-lg w-auto m-0"
            v-model="value"
            :close-on-select="true"
            :searchable="true"
            :create-option="true"
            :options="[
              { value: 'Status', label: 'Status' },
              { value: 'Active', label: 'Active' },
              { value: 'Block', label: 'Block' },
            ]"
          /> -->
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="col-xxl-3 col-sm-6 project-card"
        v-for="(item, index) of subscriptions"
        :key="index"
      >
        <div class="card">
          <div class="card-body">
            <div
              class="p-3 mt-n3 mx-n3 bg-soft-danger rounded-top"
              :style="
                new Date(item.next_due_date).getTime() < Date.now()
                  ? `background-color: red !important; color: white !important`
                  : ``
              "
            >
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <h5 class="mb-0 fs-14">
                    <router-link
                      :to="{
                        name: 'subscriptions.list',
                        params: { id: item.id },
                      }"
                      class="text-dark"
                    >
                      {{ item.code }} ({{
                        String(item.lands.length).padStart(2, "0")
                      }}
                      lots)
                    </router-link>
                  </h5>
                </div>
                <div class="flex-shrink-0">
                  <div class="d-flex gap-1 align-items-center my-n2">
                    <div class="dropdown">
                      <button
                        class="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <MoreHorizontalIcon
                          class="icon-sm"
                        ></MoreHorizontalIcon>
                      </button>

                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link
                          class="dropdown-item"
                          :to="{
                            name: 'subscriptions.details',
                            params: { id: item.id },
                          }"
                        >
                          <i
                            class="ri-eye-fill align-bottom me-2 text-muted"
                          ></i>
                          Afficher les détails
                        </router-link>
                        <!-- <router-link
                          class="dropdown-item"
                          to="/apps/projects-create"
                          ><i
                            class="ri-pencil-fill align-bottom me-2 text-muted"
                          ></i>
                          Edit</router-link
                        >
                        <div class="dropdown-divider"></div>
                        <a
                          class="dropdown-item"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#removeProjectModal"
                          ><i
                            class="ri-delete-bin-fill align-bottom me-2 text-muted"
                          ></i>
                          Remove</a
                        > -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-3">
              <div class="mb-2">
                <p class="mb-1">
                  {{ item.user?.username }} ({{ item.user?.phone }})
                </p>
              </div>

              <div class="row gy-3">
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">Status</p>
                    <div :class="`badge badge-soft-${item.status_class} fs-12`">
                      {{ item.status_text }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">Suivant</p>
                    <h5 class="fs-11">
                      {{ new Date(item.next_due_date).toLocaleString() }}
                    </h5>
                  </div>
                </div>
              </div>

              <div class="row gy-3">
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">À payer</p>
                    <div class="fs-12">{{ $formatPrice(item.total_amount) }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">Restant</p>
                    <h5 class="fs-12">{{ $formatPrice(item.monthly_amount) }}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex mb-2">
                <div class="flex-grow-1">
                  <div>Progression</div>
                </div>
                <div class="flex-shrink-0">
                  <div>{{ item.progress }}%</div>
                </div>
              </div>
              <div class="progress progress-sm animated-progess">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="`width: ${item.progress}%`"
                ></div>
                <!-- /.progress-bar -->
              </div>
              <!-- /.progress -->
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
